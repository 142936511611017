import React, {useMemo} from "react"
import {Button, Container, Divider, Stack, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {ServiceProviderType} from 'types/ServiceProvider'
import {TextFieldController} from 'components/form/TextFieldController'
import {AutocompleteController} from 'components/form/AutocompleteController'
import {serviceProviderCountry} from 'config/Options'
import {useCreateServiceProvider} from 'hooks/useCreateServiceProvider'
import {getUserId} from 'config/auth'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {serviceProviderAddFormSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {DEFAULT_SPP_END_TIME, DEFAULT_SPP_START_TIME} from "../../config/const";
import {PhoneNumberController} from "../form/PhoneNumberController";

export const AddServiceProviderDrawer = ({openServiceProvider, setOpenServiceProvider}: any) => {
    const {t} = useTranslation();
    const {AddServiceProvider} = useCreateServiceProvider()
    const userId = getUserId()
    const schema = useMemo(() => serviceProviderAddFormSchema(), [i18n.language]);

    const {handleSubmit, control, reset} = useForm<ServiceProviderType>({resolver: yupResolver(schema)})

    const toggleDrawer = () => {
        reset()
        setOpenServiceProvider(!openServiceProvider)
    }

    const submitHandler = (values: ServiceProviderType) => {
        AddServiceProvider({
            ...values,
            logoUrl: '',
            imageUrl: '',
            pricingRange: 0,
            ownerId: userId,
            startTime: DEFAULT_SPP_START_TIME,
            endTime: DEFAULT_SPP_END_TIME,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        setOpenServiceProvider(!openServiceProvider)
        reset()
    }


    return (
        <Drawer open={openServiceProvider} onClose={toggleDrawer}>
            <Container maxWidth={'sm'} sx={{paddingY: 2}}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <Stack direction={'column'}>
                        <Typography variant={'h6'} my={1}>{t('add_new_service_provider')}</Typography>
                        <Divider variant="fullWidth"/>
                        <TextFieldController control={control} name={'name'} label={'service_provider_name'}/>
                        <TextFieldController control={control} name={'registrationNumber'} label={'registration_number_optional'}/>

                        <PhoneNumberController control={control} name={'phoneNumber'} label={'phone_number'}/>

                        <TextFieldController control={control} name={'websiteUrl'} label={'website_optional'}/>
                        <TextFieldController control={control} name={'averageTimePerCustomer'} label={'average_time_per_booking_in_minutes'}
                                             type={'number'}/>
                        <TextFieldController control={control} multiline={true} name={'description'}
                                             label={'service_provider_description'}/>

                        <Typography variant={'h6'} sx={{mt: 5}} my={1}>{t('address')}</Typography>
                        <Divider variant="fullWidth"/>


                        <AutocompleteController control={control} name={'address.country'} label={'country'}
                                                options={serviceProviderCountry}/>
                        <TextFieldController control={control} name={'address.state'} label={'state_optional'}/>
                        <TextFieldController control={control} name={'address.city'} label={'city'}/>
                        <TextFieldController control={control} name={'address.streetName'} label={'street_name'}/>
                        <TextFieldController control={control} name={'address.streetNumber'} label={'street_number'}/>
                        <TextFieldController control={control} name={'address.postalCode'} label={'postal_code'}/>

                        <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
                    </Stack>
                </form>
            </Container>
        </Drawer>


    )
}