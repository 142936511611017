import moment from 'moment'

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_MASK = '__-__-____'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const TIME_FORMAT = 'HH:mm'
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const ISO_DAY_MONTH = 'DD-MM'
export const DAY_MONTH_NAME = 'DD MMM'
export const CURRENT_TIME = moment(new Date()).format("HH")
export const CURRENT_TIME_MINUTES = moment(new Date()).format("mm")
export const BOOKING_TIME_UPPER_LIMIT_MINUTES = 30
export const BOOKING_STATISTICS_COUNT_DAYS = 7
export const BOOKING_REFETCH_INTERVAL_IN_MS = 10000
export const DEFAULT_SPP_START_TIME = '10:00'
export const DEFAULT_SPP_END_TIME = '23:00'

export const MIN_NUMBER_OF_GUESTS = 1
export const MAX_NUMBER_OF_GUESTS = 9