import { createTheme } from '@mui/material/styles'
import { PLUGIN_ICON_COLOR } from 'theme/Plugin.styles'


declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tableButton: true
    walkInButton: true
    alarmButton: true
    availableWithWarningButton: true
  }
}

export const theme = createTheme({
  palette: {
    background: {default: 'transparent linear-gradient(180deg, #819EC9 0%, #96DADA 100%) 0% 0% no-repeat'},
    primary: {
      main: "#85E6CC"
    },
    secondary: {
      main: "#F8F8F8"
    },
    error: {
      main: '#FF6A63'
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    button: {
      textTransform: "none"
    }
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: PLUGIN_ICON_COLOR
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {

        paper: {
          '::backdrop': {},
          width: '40vw'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'contained'},
          style: {
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#9debd6',
            }
          },
        },
        {
          props: {variant: 'alarmButton'},
          style: {
            backgroundColor: '#FF6A63',
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#e55f59',
            }
          },
        },
        {
          props: {variant: 'tableButton'},
          style: {
            backgroundColor: '#F8F8F8',
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#85E6CC',
            }
          },
        },
        {
          props: {variant: 'tableButton', color: 'secondary'},
          style: {
            backgroundColor: '#F8F8F8',
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#FF6A63',
            }
          },
        },
        {
          props: {variant: 'walkInButton'},
          style: {
            backgroundColor: "#6A8AB9",
            color: 'white',
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#5a769f',
            }
          },
        },
        {
          props: {variant: 'availableWithWarningButton'},
          style: {
            backgroundColor: "#fee980",
            color: 'black',
            '&:hover': { // changes colors for button hover state
              backgroundColor: '#e4d173',
            }
          },
        },
      ]
    }
  }
})
