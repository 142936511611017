import {useMutation} from 'react-query'
import React from 'react'
import {CancelBookingType} from "../types/Bookings";
import {cancelBookingUnauthorized} from "../api/public/publicapi";

export const useCancelBookingUnauthorized = () => {

  const {mutate: UseCancelBooking, isSuccess} = useMutation((payload: CancelBookingType) => {
      return cancelBookingUnauthorized(payload);
    }
  )

  return {UseCancelBooking, isSuccess}
}