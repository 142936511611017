import React from 'react';
import {Button, IconButton, List, ListItem, ListItemText} from '@mui/material'
import {DeleteOutline} from '@mui/icons-material'
import CustomFileUploadButton from 'components/network/CustomFileUploadButton'
import useDeleteFile from 'hooks/serviceProvider/useDeleteFile'
import {useTranslation} from 'react-i18next'
import {useImages} from "../../hooks/serviceProvider/useImages";


const UploadLogo = () => {
    const {t} = useTranslation()
    const {images} = useImages('SERVICE_PROVIDER_LOGO')
    const {DeleteFile} = useDeleteFile('SERVICE_PROVIDER_LOGO')

    return (
        <CustomFileUploadButton fileType={'SERVICE_PROVIDER_LOGO'} label={'upload_logo'} acceptedTypes={'image/png, image/jpeg'}>
            <List dense>
                <ListItem secondaryAction={
                    images && images.length > 0 && <IconButton edge={'end'} onClick={() => DeleteFile(images[0]?.id)}>
                        <DeleteOutline/>
                    </IconButton>
                }>
                    {images && images.length > 0 ?
                        <ListItemText
                            primary={<Button variant={'outlined'} fullWidth size={'small'}
                                             style={{color: 'black'}}>{t('preview_logo')}</Button>}
                            onClick={() => window.open(images[0]?.fileUrl, '_blank')}
                            style={{cursor: 'pointer'}}
                        />
                        :
                        <ListItemText primary={t('logo_not_uploaded')}/>
                    }
                </ListItem>
            </List>
        </CustomFileUploadButton>
    );
};

export default UploadLogo;
