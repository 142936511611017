import React from 'react';
import {Grid, Typography} from '@mui/material'
import {TextFieldController} from 'components/form/TextFieldController'
import HiddenInputController from 'components/form/HiddenInputController'
import {Control} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

type props = {
    control: Control<any>
    dishNumber: number
    headerNumber: 1 | 2 | 3 | 4
    gridSize?: number
    language: 'EN' | 'DE'
}

const HighlightDishInput = ({control, dishNumber, headerNumber, gridSize = 3, language}: props) => {
    const {t} = useTranslation()

    return (
        <Grid item xs={gridSize} mt={1}>
            <Typography variant={'body1'}
                        textAlign={'center'}>{`${t('dish')} ${headerNumber} ${language}`} </Typography>
            <TextFieldController control={control} defaultValue={''}
                                 name={`highlightDishes.${language}.${dishNumber}.title`}
                                 label={'name'}/>
            <TextFieldController control={control} defaultValue={''} multiline
                                 name={`highlightDishes.${language}.${dishNumber}.description`} label={'description'}/>
            <HiddenInputController control={control} name={`highlightDishes.${language}.${dishNumber}.languageCode`}
                                   defaultValue={language}/>
        </Grid>
    );
};

export default HighlightDishInput;
