import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // detect user language
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'de',
        supportedLngs: ['de', 'en'],
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react : {
            wait: true,
            useSuspense: false
        },
        detection: {
            caches: ['cookie'],
            cookieSameSite: 'strict',
            lookupQuerystring: 'lang', // default is lng
            lookupCookie: 'next-i18next',
            order: ['querystring'], // IMPORTANT
        },
    });


export default i18n;