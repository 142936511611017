import {Control, Controller} from 'react-hook-form'
import {FormControl} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
// @ts-ignore
import {MuiTelInput, MuiTelInputCountry, MuiTelInputInfo, MuiTelInputContinent, matchIsValidTel} from 'mui-tel-input'

type props = {
    control: Control<any>
    name: string
    size?: "medium" | "small" | undefined
    defaultValue?: string | number
    label?: string
    plugin?: boolean
    disabled?: boolean
    fullWidth?: boolean
    variant?: "standard" | "outlined" | undefined
}


export const PhoneNumberController = ({
                                          control,
                                          name,
                                          defaultValue,
                                          label = '',
                                          disabled = false,
                                          size = 'medium',
                                          fullWidth = true,
                                          variant = "outlined"
                                      }: props) => {
    const {t} = useTranslation();

    return <Controller
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({field, fieldState: {error, invalid}}) => {
            return (
                <FormControl fullWidth={fullWidth} margin={'dense'}>
                    <MuiTelInput label={t(label)}
                                    variant={variant}
                                    value={field.value ? field.value : '+49'}
                                    focusOnSelectCountry
                                    disabled={disabled}
                                    size={size}
                                    error={invalid}
                                    helperText={error?.message}
                                    fullWidth
                                    onChange={(e: any) => {
                                        if (e) {
                                            // @ts-ignore
                                            e = e.replaceAll(" ", "")
                                        }
                                        return field.onChange(e)
                                    }}/>
                </FormControl>
            )
        }
        }
        name={name}/>

}