import {useQuery} from 'react-query'
import {getAvailableRestaurantTable} from 'api/private/restaurants'
import {RestaurantTables} from 'types/Restaurant'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import BookingContext from 'context/bookings/BookingContext'
import moment from "moment";

export const useRestaurantTablesAvailabilityWalkIn = () => {
    const {bookings} = useContext(BookingContext)
    const {seats, tableType, vip, page, perPage} = bookings.add_walk_in
    console.log(vip)
    const payload = {
        startDate: moment(new Date()),
        numberOfSeats: seats,
        tableType: tableType,
        channel: 'WALK_IN',
        vip: vip,
        page: page,
        size: perPage
    }
    const {defaultServiceProviderId} = useContext(ServiceProviderContext)

    const {data: availableTables, isSuccess, isError, refetch} = useQuery<RestaurantTables>(
        ['available-tables', {defaultServiceProviderId, seats, tableType, page, vip, perPage}],
        () => getAvailableRestaurantTable(defaultServiceProviderId, payload),
        {
            enabled: !!defaultServiceProviderId
        })

    return {availableTables, isSuccess, isError, refetch}
}