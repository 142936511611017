import React, {Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from 'react'
import {
    Alert,
    Box,
    Button,
    Grow,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import {defaultTimeOptions} from 'config/Options'
import {PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import PluginContext from 'plugin/PluginContext'
import {
    PLUGIN_DECREASE_SEATS,
    PLUGIN_INCREASE_SEATS,
    PLUGIN_SELECT_ADDITIONAL_OPTIONS,
    PLUGIN_SELECT_DATE,
    PLUGIN_SELECT_TABLE_TYPE,
    PLUGIN_SELECT_TIME
} from 'plugin/PluginReducer'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {
    AccessTimeOutlined,
    AddOutlined,
    DateRangeOutlined,
    PeopleOutline,
    RemoveOutlined,
    SettingsSuggestOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {OptionsType} from 'types/General'
import {DATE_FORMAT, DATE_FORMAT_MASK, ISO_DATE_FORMAT, MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS} from 'config/const'
import {useParams} from 'react-router-dom'
import 'moment/locale/de'
import {useSpAvailableTimeslots} from "../useSpAvailableTimeslots";
import {toast} from "react-toastify";
import {useServiceProviderPluginDetails} from "../useServiceProviderPluginDetails";
import {AdditionalOptionsAutocomplete} from "../../components/AdditionalOptionsAutocomplete";
import moment from "moment";

type props = {
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>
}

export const TableDetails = ({setShowErrorMessage}: props) => {
    const {t, i18n} = useTranslation()
    const {bookings, dispatch, mainColor, secondaryColor, iconColor, setPage} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })
    const {id} = useParams()
    const serviceProviderId = Number(id)
    const {startDate, startTime, seats, tableType, additionalOptions} = bookings.add_plugin_bookings

    const [selectedMonthStart, setSelectedMonthStart] = useState(moment(new Date()).startOf('month'))

    const {ServiceProviderPluginDetails} = useServiceProviderPluginDetails(serviceProviderId, selectedMonthStart, 'WEB')

    const {page} = useContext(PluginContext)

    const handleClick = useCallback(() => {
        if (startTime && startTime.length > 0) {
            setPage({
                guestNumber: false,
                guestInfo: true,
                bookDetails: false,
                bookSuccess: false
            })
        } else {
            toast.error(`${t('available_tables_not_found')}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }, [startTime])

    const {
        SPAvailableTimeslots,
        error
    } = useSpAvailableTimeslots(serviceProviderId, startDate, tableType, seats, additionalOptions)

    const [dynamicTimeOptions, setDynamicTimeOptions] = useState<OptionsType[]>(defaultTimeOptions)

    const [isCalled, setIsCalled] = useState(false)

    function shouldDisableDate<TDate>(date: TDate) {
        if (moment(startDate).isSame(date, 'day')) {
            return false
        }
        if (ServiceProviderPluginDetails) {
            return !ServiceProviderPluginDetails.availableDates.includes(moment(date).format(ISO_DATE_FORMAT));
        }
        return false;
    }


    const handleAdditionalOptionsChange = (data: any) => {
        const wheelchair = data.filter((item: any) => item.value === 'WHEELCHAIR_SUPPORT')
        if (wheelchair.length > 0) {
            const additionalOptions = ['WHEELCHAIR_SUPPORT']
            dispatch({type: PLUGIN_SELECT_ADDITIONAL_OPTIONS, payload: additionalOptions})
        } else {
            dispatch({type: PLUGIN_SELECT_ADDITIONAL_OPTIONS, payload: []})
        }
    }

    useEffect(() => {
        if (ServiceProviderPluginDetails && !isCalled) {
            dispatch({type: PLUGIN_SELECT_DATE, payload: moment(ServiceProviderPluginDetails.availableDates[0])})
            setIsCalled(true)
        }
    }, [ServiceProviderPluginDetails, dispatch, isCalled])

    useEffect(() => {
        setSelectedMonthStart(moment(startDate))
    }, [startDate])

    useEffect(() => {
        if (!error) {
            if (SPAvailableTimeslots && SPAvailableTimeslots.availableTimeslots && SPAvailableTimeslots.availableTimeslots.length > 0) {
                const dynamicOptions: OptionsType[] = []
                SPAvailableTimeslots.availableTimeslots.forEach((item: string) => {
                    dynamicOptions.push({value: item, label: item})
                })
                dispatch({type: PLUGIN_SELECT_TIME, payload: dynamicOptions[0].value})
                setDynamicTimeOptions(dynamicOptions)
            } else {
                dispatch({type: PLUGIN_SELECT_TIME, payload: ''})
                setDynamicTimeOptions([])
            }
        }
    }, [SPAvailableTimeslots, error])

    if (error?.response?.data['errorCode'] === 'TRIAL_EXPIRED' || error?.response?.data['errorCode'] === 'SERVICE_PROVIDER_IS_CLOSED') {
        setShowErrorMessage(true)
        return <Box sx={{textAlign: 'center'}}>
            <Alert severity={'error'} sx={{
                textAlign: 'center',
                width: 303,
                marginTop: 25,
                '& .MuiAlert-message': {textAlign: "center", width: "inherit"}
            }}>
                {t('temporarily_closed')}
            </Alert>
        </Box>
    }

    return (
        <>
            <Grow in={page.guestNumber} style={{transformOrigin: '0 0 0'}}
                  {...(page.guestNumber ? {timeout: 1000} : {})}>

                <Table style={{borderBottom: "none"}}>
                    <TableHead>
                        <TableRow color={'primary'}>
                            <TableCell colSpan={2}>
                                <Typography variant={'h6'}>{t(`table_details`)}</Typography>
                            </TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{borderBottom: "none"}}>
                                <PeopleOutline fontSize={'medium'} className={classes.myIcon}/>
                            </TableCell>
                            <TableCell style={{borderBottom: "none"}}>
                                <Typography variant={'body2'}><strong>{t('number_of_guests')}</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <IconButton className={classes.myIcon}
                                                onClick={() => seats > MIN_NUMBER_OF_GUESTS && dispatch({type: PLUGIN_DECREASE_SEATS})}>
                                        <RemoveOutlined/>
                                    </IconButton>

                                    <Typography variant={'body1'}>{seats}</Typography>
                                    <IconButton className={classes.myIcon}
                                                onClick={() => seats <= MAX_NUMBER_OF_GUESTS && dispatch({type: PLUGIN_INCREASE_SEATS})}>
                                        <AddOutlined/>
                                    </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{borderBottom: "none"}}>
                                <TableRestaurantOutlined fontSize={'medium'} className={classes.myIcon}/>
                            </TableCell>
                            <TableCell style={{borderBottom: "none"}}>
                                <Typography variant={'body2'}><strong>{t('table_preference')}</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                <Select value={tableType} fullWidth size={'small'} variant={'standard'}
                                        onChange={(e) => dispatch({
                                            type: PLUGIN_SELECT_TABLE_TYPE,
                                            payload: e.target.value
                                        })}>
                                    <MenuItem value={"INDOOR"}>{t('INDOOR')}</MenuItem>
                                    <MenuItem value={"OUTDOOR"}>{t('OUTDOOR')}</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        {ServiceProviderPluginDetails && ServiceProviderPluginDetails.additionalOptions && ServiceProviderPluginDetails.additionalOptions.includes('WHEELCHAIR_SUPPORT') &&
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}>
                                    <SettingsSuggestOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none"}}>
                                    <Typography
                                        variant={'body2'}><strong>{t('additional_options')}</strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <AdditionalOptionsAutocomplete onChange={handleAdditionalOptionsChange}
                                                                   additionalOptions={additionalOptions.map((option: any) => (
                                                                       {value: option, label: option}
                                                                   ))}/>
                                </TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell style={{borderBottom: "none"}}>
                                <DateRangeOutlined fontSize={'medium'} className={classes.myIcon}/>
                            </TableCell>
                            <TableCell style={{borderBottom: "none"}}>
                                <Typography variant={'body2'}><strong>{t('date')}</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                <Box mt={1}>
                                    <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
                                        <DatePicker
                                            shouldDisableDate={(date) => {
                                                return shouldDisableDate(date);
                                            }}
                                            disablePast={true}
                                            value={startDate}
                                            onMonthChange={(month) => {
                                                setSelectedMonthStart(moment(month))
                                            }}
                                            inputFormat={DATE_FORMAT}
                                            mask={DATE_FORMAT_MASK}
                                            PaperProps={{
                                                sx: {marginTop: '-230px', marginLeft: '-180px'}
                                            }}
                                            onOpen={() => {
                                                setSelectedMonthStart(moment(startDate))
                                            }}
                                            onClose={() => {
                                                setSelectedMonthStart(moment(startDate))
                                            }}
                                            onChange={e => {
                                                dispatch({type: PLUGIN_SELECT_DATE, payload: e})
                                            }}
                                            renderInput={(params) => <TextField {...params} variant={'standard'}
                                                                                style={{width: 130}} size={'small'}/>}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{borderBottom: "none"}}>
                                <AccessTimeOutlined fontSize={'medium'} className={classes.myIcon}/>
                            </TableCell>
                            <TableCell style={{borderBottom: "none"}}>
                                <Typography variant={'body2'}><strong>{t('time')}</strong></Typography>
                            </TableCell>
                            <TableCell padding={'checkbox'} style={{borderBottom: "none", padding: 0}}>
                                <Select value={startTime} fullWidth size={'small'} variant={'standard'}
                                        onChange={(e) => {
                                            dispatch({type: PLUGIN_SELECT_TIME, payload: e.target.value})
                                        }}>
                                    {dynamicTimeOptions.map(item => {
                                        return (
                                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grow>
            <Button className={classes.submitButton} sx={{
                width: 80, position: 'absolute', top: 500, left: 250,
                backgroundColor: `${secondaryColor}`,
                color: 'white !important',
                transition: 'all .4s ease !important',
                '&:hover': { // changes colors for button hover state
                    opacity: '0.7 !important',
                    backgroundColor: `${secondaryColor}`
                }
            }} onClick={handleClick}>{t('next')}</Button>
        </>
    )
}
