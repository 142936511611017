import React, {useContext, useEffect, useState} from 'react'
import {Box, Grid, MenuItem, Select, Stack, useMediaQuery} from '@mui/material'
// @ts-ignore
import logo from 'skiperio-logo.png'
import {useTranslation} from 'react-i18next'
import {PluginStyles} from 'theme/Plugin.styles'
import {TableDetails} from 'plugin-horizontal/steps/TableDetails'
import {GuestDetails} from 'plugin-horizontal/steps/GuestDetails'
import PluginContext from 'plugin/PluginContext'
import {BookDetails} from 'plugin-horizontal/steps/BookDetails'
import {BookSuccess} from 'plugin-horizontal/steps/BookSuccess'
import moment from "moment/moment";
import 'moment/locale/de'
import {convertHexToRGBA, hideGoogleRecaptchaBadgeForPlugin} from "../config/Utils";

export const Plugin = () => {
    const {t, i18n} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''
    moment.updateLocale(getCurrentLng(), {week: {dow: 1}})

    useEffect(() => {
        hideGoogleRecaptchaBadgeForPlugin()
    }, [])

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            moment.updateLocale(lang, {week: {dow: 1}})
        })
    }
    const {page, mainColor, secondaryColor, iconColor, isStylesCalled} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${convertHexToRGBA(mainColor, .7)}`,
        secondaryColor: secondaryColor,
        iconColor: `${iconColor}`
    })

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    return (
        <>
            {isStylesCalled &&
                <Grid container spacing={0} direction="column" className={classes.horizontalMainWrapper}>
                    <Grid container direction="row" spacing={0} justifyContent="space-between">
                        <Grid item width={100}>
                            {showErrorMessage ?
                                <Box sx={{flexGrow: 1}}/>
                                :
                                <Box component="img" alt="Skiper Logo" src={'/textlogo.png'}
                                     className={classes.logo}/>
                            }
                        </Grid>
                        <Grid item width={100}>
                            <Select value={getCurrentLng().toLowerCase()}
                                    className={classes.horizontalLanguageSwitch}
                                    onChange={(e) => changeLanguageHandler(e.target.value.toLowerCase())}>
                                <MenuItem value={"en"}>EN</MenuItem>
                                <MenuItem value={"de"}>DE</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md sx={{margin:"5px"}}>
                            {page.guestNumber && <TableDetails setShowErrorMessage={setShowErrorMessage}/>}
                            {page.guestInfo && <GuestDetails/>}
                            {page.bookDetails && <BookDetails/>}
                            {page.bookSuccess && <BookSuccess/>}

                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}