import React, {useEffect, useState} from "react"
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useCancelBookingUnauthorized} from "../hooks/useCancelBookingUnauthorized";
import {Box, Grid} from "@mui/material"
import {SuccessErrorBox} from "./SuccessErrorBox";
import ReCaptchaV3 from "../components/ReCaptchaV3";
import {ForgotPasswordInputType} from "../types/Auth";
import {hideGoogleRecaptchaBadgeForPlugin} from "../config/Utils";

export const CancelBooking = () => {

    const {UseCancelBooking, isSuccess} = useCancelBookingUnauthorized()

    const [searchParams] = useSearchParams();
    const {t} = useTranslation()

    const [values, setValues] = useState<ForgotPasswordInputType | null>(null)

    const callback = () => {
        if (searchParams && searchParams.get("token") && searchParams.get("id")) {
            // @ts-ignore
            setValues({bookingId: searchParams.get("id"), token: searchParams.get("token")})
        }
    }

    useEffect(() => {
        hideGoogleRecaptchaBadgeForPlugin()
    }, [])

    return (
        <>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" minHeight="100vh"
                  sx={{backgroundColor: "#fbfafd"}}>
                {values &&
                    <SuccessErrorBox isSuccess={isSuccess} message = {'booking_successfully_canceled'}/>
                }
                <Box sx={{height: '50px', textAlign: 'center'}}><ReCaptchaV3 values={values} callback={callback}
                                                                              mutationFN={UseCancelBooking} showText={true}/></Box>
            </Grid>
        </>
    )
}