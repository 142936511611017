import React, { useContext, useMemo } from "react"
import {Alert, Button, Container, Divider, Stack, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { useCreateBooking } from 'hooks/bookings/useCreateBooking'
import { CreateBookingType } from 'types/Bookings'
import BookingContext from 'context/bookings/BookingContext'
import moment from 'moment/moment'
import { bookingAddFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import {ISO_DATE_TIME_FORMAT} from "../../config/const";
import {PhoneNumberController} from "../form/PhoneNumberController";


export const AddBookingDrawer = ({openBooking, setOpenBooking, table}: any) => {
  const {t} = useTranslation();

  const {AddBooking} = useCreateBooking()
  const schema = useMemo(() => bookingAddFormSchema(), [i18n.language]);

  const {handleSubmit, control, reset} = useForm({defaultValues: table, resolver: yupResolver(schema)})
  const {selectedDateTime, bookings} = useContext(BookingContext)
  const {startTime, startDate} = bookings.add_bookings
  const toggleDrawer = () => {
    reset(table)
    setOpenBooking(!openBooking)
  }
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const submitHandler = (values: CreateBookingType) => {
    AddBooking({
      tableId: table.id,
      bookingTime: moment(selectedDateTime).format(ISO_DATE_TIME_FORMAT),
      guestName: values.guestName,
      guestMobile: values.guestMobile,
      guestEmail: values.guestEmail,
      numberOfGuests: values.numberOfGuests,
      comment: values.comment,
      channel: 'DIRECT',
      languageCode: getCurrentLng().toUpperCase()
    })
    setOpenBooking(!openBooking)
  }

  return (
    <Drawer open={openBooking} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h4'}>{`${t('your_booking_details')}`}</Typography>
          <Typography variant={'h6'}>
            {table.vip && <span><b>{t('VIP')}</b>&nbsp;</span>}
            {`${t('table_with')} ${table.remainingSeats} ${t('seats').toLowerCase()} at ${startDate} ${startTime}`}
          </Typography>
          {table.outsideOfAvailabilityHours &&
              <Alert sx={{mb: 2}} severity={'info'}>{t('booking_outside_of_availability')}</Alert>
          }
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} name={'guestName'} label={'customer_name'}/>
            <PhoneNumberController control={control} name={'guestMobile'} label={'mobile_optional'}/>
            <TextFieldController control={control} name={'guestEmail'} label={'email_optional'}/>
            <TextFieldController control={control} name={'numberOfGuests'} type={'number'} label={'number_of_guests'}/>
            <TextFieldController control={control} multiline={true} name={'comment'} label={'comment_optional'}/>

            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>


  )
}