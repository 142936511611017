import React from 'react'
import {
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import {PluginProps, PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {
    AccessTimeOutlined,
    AddOutlined,
    DateRangeOutlined,
    PeopleOutline,
    RemoveOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {DATE_FORMAT, DATE_FORMAT_MASK} from 'config/const'
import 'moment/locale/de'

export const PluginDemo = ({mainColor, secondaryColor, iconColor}: PluginProps) => {
    const classes = PluginStyles({mainColor: mainColor, secondaryColor: secondaryColor, iconColor: iconColor})
    const {t, i18n} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''

    return (
        <>
            <Grid container spacing={0} direction="column" sx={{maxWidth: 350, maxHeight: 550}}>
                <Box className={classes.mainWrapper}>
                    <Box className={classes.inputWrapper} alignItems={'end'}>
                        <Stack direction={'row'}>
                            <Box component="img" alt="Skiper Logo" src={'/textlogo.png'} className={classes.logo}/>
                            <Select disabled={true} value={getCurrentLng().toLowerCase()}
                                    className={classes.languageSwitch}>
                                <MenuItem value={"en"}>EN</MenuItem>
                                <MenuItem value={"de"}>DE</MenuItem>
                            </Select>
                        </Stack>

                        <Stack direction={'column'}>

                            <Table style={{borderBottom: "none"}}>
                                <TableHead>
                                    <TableRow color={'primary'}>
                                        <TableCell colSpan={2}>
                                            <Typography variant={'h6'}>{t(`table_details`)}</Typography>
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <PeopleOutline fontSize={'medium'} className={classes.myIcon}/>
                                        </TableCell>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <Typography
                                                variant={'body2'}><strong>{t('number_of_guests')}</strong></Typography>
                                        </TableCell>
                                        <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                            <Stack direction={'row'} justifyContent={'center'} spacing={2}
                                                   alignItems={'center'}>
                                                <IconButton style={{backgroundColor: 'transparent'}}
                                                            className={classes.myIcon}>
                                                    <RemoveOutlined style={{backgroundColor: 'transparent'}}/>
                                                </IconButton>

                                                <Typography variant={'body1'}>1</Typography>
                                                <IconButton style={{backgroundColor: 'transparent'}}
                                                            className={classes.myIcon}>
                                                    <AddOutlined style={{backgroundColor: 'transparent'}}/>
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <TableRestaurantOutlined fontSize={'medium'} className={classes.myIcon}/>
                                        </TableCell>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <Typography
                                                variant={'body2'}><strong>{t('table_preference')}</strong></Typography>
                                        </TableCell>
                                        <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                            <Select value={'INDOOR'} fullWidth size={'small'} disabled={true}
                                                    variant={'standard'}>
                                                <MenuItem value={"INDOOR"}>{t('INDOOR')}</MenuItem>
                                                <MenuItem value={"OUTDOOR"}>{t('OUTDOOR')}</MenuItem>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <DateRangeOutlined fontSize={'medium'} className={classes.myIcon}/>
                                        </TableCell>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <Typography variant={'body2'}><strong>{t('date')}</strong></Typography>
                                        </TableCell>
                                        <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                            <Box mt={1}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}
                                                                      locale={i18n.language}>
                                                    <DatePicker
                                                        disabled={true}
                                                        onChange={() => {
                                                            void 0
                                                        }}
                                                        value={new Date()}
                                                        inputFormat={DATE_FORMAT}
                                                        mask={DATE_FORMAT_MASK}
                                                        PaperProps={{
                                                            sx: {marginTop: '-230px', marginLeft: '-180px'}
                                                        }}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            variant={'standard'}
                                                                                            style={{width: 130}}
                                                                                            size={'small'}/>}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <AccessTimeOutlined fontSize={'medium'} className={classes.myIcon}/>
                                        </TableCell>
                                        <TableCell style={{borderBottom: "none"}}>
                                            <Typography variant={'body2'}><strong>{t('time')}</strong></Typography>
                                        </TableCell>
                                        <TableCell padding={'checkbox'} style={{borderBottom: "none"}}>
                                            <Select disabled={true} value={'18:00'} fullWidth size={'small'}
                                                    variant={'standard'}>
                                                <MenuItem value={'18:00'}>{'18:00'}</MenuItem>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Button sx={{
                                width: 80, alignSelf: 'end',
                                marginTop: 10,
                                backgroundColor: `${secondaryColor}`,
                                color: 'white !important',
                                transition: 'all .4s ease !important',
                                '&:hover': { // changes colors for button hover state
                                    opacity: '0.7 !important',
                                    backgroundColor: `${secondaryColor}`
                                }
                            }}>{t('next')}</Button>
                        </Stack>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}
