import React, { useCallback } from 'react'
import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { usePublicHolidays } from 'hooks/usePublicHolidays'
import { useUpdatePublicHolidays } from 'hooks/useUpdatePublicHolidays'
import { useTranslation } from 'react-i18next'
import moment from "moment";
import {DATE_FORMAT, ISO_DATE_FORMAT, ISO_DAY_MONTH, DAY_MONTH_NAME} from "../../config/const";
import i18n from "i18next";


export const PublicHolidays = () => {
  const {t} = useTranslation()
  const {publicHolidays} = usePublicHolidays()

  const {UpdatePublicHolidays} = useUpdatePublicHolidays()

  const handleAttendingChange = useCallback((id: number, holidaysState: boolean) => {

    const updatedHolidays = publicHolidays[id]

    updatedHolidays.selectedByServiceProvider = holidaysState

    const newHolidays = [...publicHolidays]
    newHolidays[id] = updatedHolidays
  }, [publicHolidays])

  const submit = useCallback(() => {
    const tmp: any = {publicHolidays: []}
    const filtered = publicHolidays.filter(item => item.selectedByServiceProvider)
    filtered.forEach(item => tmp.publicHolidays.push(item.id))
    UpdatePublicHolidays(tmp)
  }, [publicHolidays])
  return (

    <>
      <Typography variant={'h5'} textAlign={"center"} mt={3} mb={3}>{t("public_holidays_header")}</Typography>
      <Grid container alignItems={'center'}>
        {publicHolidays.map((holiday: any, index: number) => {
          return (
            <Grid key={index} item md={6} justifyContent={'center'}>
              <FormControlLabel control={<Switch defaultChecked={holiday.selectedByServiceProvider}
                                                 onChange={() => handleAttendingChange(index, !holiday.selectedByServiceProvider)}/>}
                                label={`${t(`${holiday.name.replace(/ /g, '_').toLowerCase()}`)} - ${moment(holiday.date, ISO_DATE_FORMAT).format(DAY_MONTH_NAME)} (${holiday.type})`}/>

            </Grid>
          )
        })}
      </Grid>
      <Button variant={'contained'} sx={{mt: 2}} type={'submit'} onClick={submit}>{t('submit')}</Button>
    </>
  )
}