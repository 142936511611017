import React from "react"
import { MenuItem, Select } from '@mui/material'
import AppBar from '@mui/material/AppBar';
import { Outlet, useNavigate } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
// @ts-ignore
import logo from '../../logo.svg'
import Box from '@mui/material/Box'
import { topBarUnauthorizedStyles } from "theme/TopBarUnauthorized.styles";
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css';
import moment from "moment/moment";
import 'moment/locale/de'
import 'moment/locale/en-gb'

export const Header = () => {
  const classes = topBarUnauthorizedStyles()
  const {t, i18n} = useTranslation()

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';
  moment.updateLocale(getCurrentLng(), {week: {dow: 1}})

  const changeLanguageHandler = (lang: string) => {
    moment.updateLocale(lang, {week: {dow: 1}})
    i18n.changeLanguage(lang)
  }

  return <>
    <AppBar position="absolute" color={'secondary'} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <Box onClick={() => {
          location.href ='https://skiper.io'
        }} component="img" alt="Skiper Logo" src={'https://www.portal.skiper.io/textlogo.png'} className={classes.logo}/>
        <Box color="inherit" className={classes.divider}/>

        <Box mr={5}>
          <Select value={getCurrentLng().toLowerCase()} className={classes.languageSwitch}
                  onChange={(e) => changeLanguageHandler(e.target.value.toLowerCase())}>
            <MenuItem value={"en"}>EN</MenuItem>
            <MenuItem value={"de"}>DE</MenuItem>
          </Select>
        </Box>

      </Toolbar>
    </AppBar>
  </>


}