import { OptionsType } from 'types/General'
import {MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS} from "./const";

export const typeServiceProvider: OptionsType[] = [
  {value: 'RESTAURANT', label: 'RESTAURANT'},
  {value: 'BEAUTY', label: 'BEAUTY'}
]

export const ServiceProviderSubType: OptionsType[] = [
  {value: 'ASIAN_CUISINE', label: 'ASIAN_CUISINE'},
  {value: 'GERMAN_CUISINE', label: 'GERMAN_CUISINE'},
]

export const serviceProviderCountry: OptionsType[] = [
  {value: 'Germany', label: 'Germany'}
]

export const staticAdditionalOptions: OptionsType[] = [
  {value: 'WHEELCHAIR_SUPPORT', label: 'WHEELCHAIR_SUPPORT'}
]

export const memberRoles: OptionsType[] = [
  {value: 'ADMIN', label: 'ADMIN'},
  {value: 'REGULAR', label: 'REGULAR'}
]

export const printerTypes: OptionsType[] = [
  {value: 'A4', label: 'A4'},
  {value: 'RECEIPT_PRINTER', label: 'RECEIPT_PRINTER'}
]

//Temp
export const bookingSeats: OptionsType[] = Array.from(
    { length: MAX_NUMBER_OF_GUESTS - MIN_NUMBER_OF_GUESTS + 1 },
    (_, index) => ({
      value: index + MIN_NUMBER_OF_GUESTS,
      label: `${index + MIN_NUMBER_OF_GUESTS}`,
    })
);

export const bookingsTablesType: OptionsType[] = [
  {value: 'INDOOR', label: 'INDOOR'},
  {value: 'OUTDOOR', label: 'OUTDOOR'}
]

export const tableVipOptions: OptionsType[] = [
  {value: 'VIP', label: 'VIP'},
  {value: 'STANDARD', label: 'STANDARD'}
]

export const bookingsStatus: OptionsType[] = [
  {value: 'ACTIVE', label: 'ACTIVE'},
  {value: 'COMPLETED', label: 'COMPLETED'},
  {value: 'CANCELLED', label: 'CANCELLED'}
]

export const defaultTimeOptions: OptionsType[] = [
  {value: '00:00', label: '00:00'},
  {value: '00:30', label: '00:30'},
  {value: '01:00', label: '01:00'},
  {value: '01:30', label: '01:30'},
  {value: '02:00', label: '02:00'},
  {value: '02:30', label: '02:30'},
  {value: '03:00', label: '03:00'},
  {value: '03:30', label: '03:30'},
  {value: '04:00', label: '04:00'},
  {value: '04:30', label: '04:30'},
  {value: '05:00', label: '05:00'},
  {value: '05:30', label: '05:30'},
  {value: '06:00', label: '06:00'},
  {value: '06:30', label: '06:30'},
  {value: '07:00', label: '07:00'},
  {value: '07:30', label: '07:30'},
  {value: '08:00', label: '08:00'},
  {value: '08:30', label: '08:30'},
  {value: '09:00', label: '09:00'},
  {value: '09:30', label: '09:30'},
  {value: '10:00', label: '10:00'},
  {value: '10:30', label: '10:30'},
  {value: '11:00', label: '11:00'},
  {value: '11:30', label: '11:30'},
  {value: '12:00', label: '12:00'},
  {value: '12:30', label: '12:30'},
  {value: '13:00', label: '13:00'},
  {value: '13:30', label: '13:30'},
  {value: '14:00', label: '14:00'},
  {value: '14:30', label: '14:30'},
  {value: '15:00', label: '15:00'},
  {value: '15:30', label: '15:30'},
  {value: '16:00', label: '16:00'},
  {value: '16:30', label: '16:30'},
  {value: '17:00', label: '17:00'},
  {value: '17:30', label: '17:30'},
  {value: '18:00', label: '18:00'},
  {value: '18:30', label: '18:30'},
  {value: '19:00', label: '19:00'},
  {value: '19:30', label: '19:30'},
  {value: '20:00', label: '20:00'},
  {value: '20:30', label: '20:30'},
  {value: '21:00', label: '21:00'},
  {value: '21:30', label: '21:30'},
  {value: '22:00', label: '22:00'},
  {value: '22:30', label: '22:30'},
  {value: '23:00', label: '23:00'},
  {value: '23:30', label: '23:30'}
]

export const minutes: OptionsType[] = [
  {value: '00', label: '00'},
  {value: '30', label: '30'}
]

export const marks = [
  {value: 1, label: '1',},
  {value: 2, label: '2',},
  {value: 3, label: '3',},
  {value: 4, label: '4',},
  {value: 5, label: '5',},
  {value: 6, label: '6',},
  {value: 7, label: '7',},
  {value: 8, label: '8',},
]

export const restaurantTablesStatus: OptionsType[] = [
  {value: 'ACTIVATED', label: 'ACTIVATED'},
  {value: 'DEACTIVATED', label: 'DEACTIVATED'}
]