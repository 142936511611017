import axios from 'axios';
import { getJwtToken } from 'config/auth'


export const privateAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

privateAxiosClient.interceptors.request.use(function (config) {
  const auth = getJwtToken()
  // @ts-ignore
  config.headers.Authorization = auth ? `Bearer ${auth}` : '';
  return config;
});

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})
