import React, {useMemo, useState} from "react"
import {Box, Button, Grid, Stack} from '@mui/material'
import {Link} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {loginStyles} from 'theme/Login.styles'
import {SubmitHandler, useForm} from "react-hook-form";
import {LoginInputType} from "types/Auth";
import {useTranslation} from 'react-i18next'
import {useLogin} from 'hooks/useLogin'
import {TextFieldController} from 'components/form/TextFieldController'
import {yupResolver} from '@hookform/resolvers/yup'
import {loginFormSchema} from "config/YupSchemaValidations"
// @ts-ignore
import portalLogo from '../portal_logo.svg'
import i18n from 'i18next'
import ReCaptchaV3 from "../components/ReCaptchaV3";

export const Login = () => {
  const {t} = useTranslation()
  const classes = loginStyles()
  const schema = useMemo(() => loginFormSchema(), [i18n.language]);

  const {handleSubmit, control} = useForm<LoginInputType>({resolver: yupResolver(schema)})
  const {Login} = useLogin()

  const [values, setValues] = useState<LoginInputType | null>(null)

  const loginSubmitHandler: SubmitHandler<LoginInputType> = (values: LoginInputType) => {
    values.email = values.email.toLowerCase()
    // How to wrap next lines with verifycaptcha call
    setValues({...values})
  }

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" minHeight="100vh">

      <Box className={classes.loginWrapper}>
        <Box component="img" alt="Skiper Portal Logo" src={portalLogo} style={{width: 200, marginBottom: 20}}/>

        <Typography variant={'h4'} className={classes.loginText} m={3}><b>{t('Login')}</b></Typography>
        <Box component="form" onSubmit={handleSubmit(loginSubmitHandler)} noValidate className={classes.formWrapper}>
          <Stack direction={'column'}>
            <TextFieldController control={control} name={'email'} label={'email'}/>

            <TextFieldController control={control} name={'password'} label={'password'} type="password"/>
            <Link to="/forgot-password" className={classes.link}>
              <Typography>
                {t('forgot_password')}
              </Typography>
            </Link>
            <ReCaptchaV3 values={values} mutationFN={Login}/>

            <Button type="submit" fullWidth variant="contained" className={classes.loginButton}>
              {t('login')}
            </Button>
            <Link to="/registration" className={classes.link}>
              <Typography>
                {t('dont_have_an_account')}
              </Typography>
            </Link>

          </Stack>
        </Box>
      </Box>
      <Grid item container direction={'row'} justifyContent={'center'}>
        <Grid item sx={{margin:1}}>
        <a href="https://play.google.com/store/apps/details?id=io.skiper.portal&pli=1" target="_blank" rel="noreferrer">
          <img src={'/google.png'} width={190} alt="Google play store logo"/>
        </a>
        </Grid>
        <Grid item sx={{margin:1}}>
        <a href="https://apps.apple.com/de/app/skiper-portal/id6443813492" target="_blank" rel="noreferrer">
          <img src={'/ios.png'} width={190}  alt="IOS app store logo"/>
        </a>
        </Grid>
      </Grid>
    </Grid>
  )
}