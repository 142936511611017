import React, {useEffect} from 'react';
import {Button, Grid} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useUpdateHighlightDishes} from 'hooks/serviceProvider/useUpdateHighlightDishes'
import HighlightDishInput from 'components/HighlightDishInput'
import {useHighlightDishes} from 'hooks/serviceProvider/useHighlightDishes'
import {useTranslation} from 'react-i18next'
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {highlightDishSchema} from "../highlightDishSchema";


const HighlightDishes = () => {
    const {t} = useTranslation()
    const {highlightDishes, highlightDishesArray} = useHighlightDishes()
    const {handleSubmit, control, reset} = useForm({defaultValues: highlightDishesArray, resolver: yupResolver(highlightDishSchema())})
    const {UpdateHighlightDishes} = useUpdateHighlightDishes()

    const onSubmit = (values: any) => {
        const en = values?.highlightDishes?.EN.filter((highlightDish: any) => highlightDish.title && highlightDish.description)
        const de = values?.highlightDishes?.DE.filter((highlightDish: any) => highlightDish.title && highlightDish.description)
        const filterEmptyValues = {
            highlightDishes: [...en, ...de]
        }
        UpdateHighlightDishes(filterEmptyValues)
    }

    useEffect(() => {
        if (highlightDishes) {
            reset(highlightDishesArray)
        }
    }, [highlightDishes])

    return (
        <>
            {highlightDishes &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <HighlightDishInput control={control} dishNumber={0} headerNumber={1} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={1} headerNumber={2} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={2} headerNumber={3} language={'EN'}/>
                        <HighlightDishInput control={control} dishNumber={3} headerNumber={4} language={'EN'}/>
                    </Grid>
                    <Grid container spacing={1}>
                        <HighlightDishInput control={control} dishNumber={0} headerNumber={1} language={'DE'}/>
                        <HighlightDishInput control={control} dishNumber={1} headerNumber={2} language={'DE'}/>
                        <HighlightDishInput control={control} dishNumber={2} headerNumber={3} language={'DE'}/>
                        <HighlightDishInput control={control} dishNumber={3} headerNumber={4} language={'DE'}/>
                    </Grid>
                    <Button variant={'contained'} fullWidth type={'submit'}>{t('save')}</Button>
                </form>}
        </>
    );
};

export default HighlightDishes;
