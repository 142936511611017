import React, {useEffect, useMemo, useState} from 'react'
import {Alert, Grid, Stack} from '@mui/material'
import {TextFieldController} from 'components/form/TextFieldController'
import {useServiceProvider} from 'hooks/useServiceProvider'
import PriceRangeSelector from 'components/PriceRangeSelector'
import {AutocompleteController} from 'components/form/AutocompleteController'
import CheckboxController from 'components/form/CheckboxController'
import {useTranslation} from 'react-i18next'
import UploadLogo from "../../network/UploadLogo";
import {useAllTags} from "../../../hooks/serviceProvider/useAllTags";
import {OptionsType} from 'types/General'
import {useAllPaymentMethods} from "../../../hooks/serviceProvider/useAllPaymentMethods";


export const EditableValues = ({control}: any) => {
    const {t} = useTranslation()
    const {serviceProvider} = useServiceProvider()

    const {AllTags} = useAllTags()

    const {AllPaymentMethods} = useAllPaymentMethods()

    const [dynamicTagsOptions, setDynamicTagsOptions] = useState<OptionsType[]>([])

    const [dynamicPaymentMethodsOptions, setPaymentMethodsOptions] = useState<OptionsType[]>([])

    useEffect(() => {
        if (AllTags) {
            setDynamicTagsOptions(AllTags?.map((tag) => (
                {value: tag.id, label: tag.name}
            )))
        }
    }, [AllTags])

    useEffect(() => {
        if (AllPaymentMethods) {
            setPaymentMethodsOptions(AllPaymentMethods?.map((pm) => (
                {value: pm.id, label: pm.name}
            )))
        }
    }, [AllPaymentMethods])


    const tags = useMemo(() => {
        return serviceProvider?.tags ? serviceProvider?.tags?.map((tag: any) => ({value: tag.id, label: tag.name})) : []
    }, [serviceProvider?.tags])

    const paymentMethods = useMemo(() => {
        return serviceProvider?.paymentMethods ? serviceProvider?.paymentMethods.map((a: any) => ({
            value: a.id,
            label: a.name
        })) : []
    }, [serviceProvider?.paymentMethods])

    return (
        <Grid item xs={6} mt={3}>

            {serviceProvider && dynamicTagsOptions && dynamicPaymentMethodsOptions &&
                <Stack direction={'column'}>


                    <TextFieldController control={control} name={'averageTimePerCustomer'} defaultValue={''}
                                         label={'average_time_per_booking_in_minutes'} type={'number'} size={'small'}/>

                    <AutocompleteController control={control} name={'tagIds'} size={'small'}
                                            defaultValue={tags}
                                            label={'select_tags'}
                                            options={dynamicTagsOptions} multiple={true}/>

                    <AutocompleteController control={control} name={'paymentMethodIds'} size={'small'}
                                            defaultValue={paymentMethods}
                                            label={'select_payment_methods'}
                                            options={dynamicPaymentMethodsOptions} multiple={true}/>

                    <TextFieldController control={control} name={'fbAccount'} label={'fb_account'}
                                         type={'text'} size={'small'}/>

                    <TextFieldController control={control} name={'instagramAccount'} label={'instagram_account'}
                                         type={'text'} size={'small'}/>

                    <PriceRangeSelector control={control} name={'pricingRange'} defaultValue={1}/>
                    <Alert severity={'info'}>{t('example')}: email@example.com;email2@example.com</Alert>
                    <TextFieldController control={control} name={'bookingNotificationEmail'}
                                         label={'email_placeholder'}/>
                    <CheckboxController control={control} name={'bookingEmailsEnabled'}
                                        label={'booking_notification_emails_enabled'}/>
                    <CheckboxController control={control} name={'wheelchairSupport'}
                                        label={'wheelchair_support'}/>

                    <UploadLogo/>
                </Stack>
            }

        </Grid>

    )
}