import moment from 'moment/moment'
import { AlertColor } from '@mui/material'
import { useTranslation } from 'react-i18next'


type DataDiff = {
  severity: AlertColor | undefined
  trialText: string
  isExpired: boolean
  width: number
}

export const useDateDiff = (planExpiryDate: string | null | undefined) => {
  const {t} = useTranslation()
  const dataDiff: DataDiff = {
    severity: undefined,
    trialText: '',
    isExpired: false,
    width: 0
  }
  if (planExpiryDate) {
    const expirationDate = moment(planExpiryDate)
    const currentDate = moment(new Date())
    const daysLeft = expirationDate.diff(currentDate, 'days') + 1

    if (daysLeft > 15) {
      dataDiff.severity = 'warning'
      dataDiff.trialText = `Free Trial (${daysLeft} ${t('days_left')})`
    } else {
      if (daysLeft > 0) {
        dataDiff.severity = 'error'
        dataDiff.trialText = `Free Trial (${daysLeft} ${t('days_left')})`
      } else {
        dataDiff.severity = 'error'
        dataDiff.trialText = `Free Trial Expired`
        dataDiff.isExpired = true
      }
    }
  }

  return {dataDiff}
}